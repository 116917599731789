.status__content a,
.reply-indicator__content a,
.edit-indicator__content a,
.link-footer a,
.status__content__read-more-button,
.status__content__translate-button {
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &.mention {
    text-decoration: none;

    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus,
    &:active {
      span {
        text-decoration: none;
      }
    }
  }
}

.status__content a,
.reply-indicator__content a,
.edit-indicator__content a {
  color: $highlight-text-color;
}

.nothing-here {
  color: $darker-text-color;
}

.report-dialog-modal__textarea::placeholder {
  color: $inverted-text-color;
}

.link-button:disabled {
  cursor: not-allowed;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none !important;
  }
}
